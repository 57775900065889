<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 g c">
      <div class="card">
        <div class="card-header">
          <h4>
            <span v-if="!edit">
              <i class="fa fa-plus"></i>
              اضافة مشرف جديد
            </span>
            <span v-if="edit">
              <i class="fa fa-edit"></i>
              تعديل المشرف
            </span>
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for=""> الاسم </label>
            <input type="text" class="form-control" v-model="admin.name" />
          </div>
          <div class="form-group">
            <label for=""> اسم المستخدم </label>
            <input type="text" class="form-control" v-model="admin.username" />
          </div>
          <div class="form-group">
            <label for=""> الجوال </label>
            <input type="text" class="form-control" v-model="admin.phone" />
          </div>
          <div class="form-group">
            <label for=""> كلمة المرور </label>
            <input type="text" class="form-control" v-model="admin.password" />
          </div>
          <div class="form-group">
            <label for="">ملاحظات</label>
            <textarea
              class="form-control"
              v-model="admin.notes"
              rows="3"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="">القسم</label>
            <select class="form-control" v-model="admin.section_id">
              <option :value="null">-- اختر --</option>
              <template v-for="section in sections">
                <option :value="section._id" :key="section._id">
                  {{ section.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="row" v-if="admin.bus == 'nothing'">
            <div class="col-12 col-lg-6 g" v-if="checkPer('admins_bus-edit')">
              <button
                class="btn btn-primary btn-block"
                @click="admin.bus = true"
              >
                <i class="fa fa-truck"></i>
                مشرف للباصات
              </button>
            </div>
            <div class="col-12 col-lg-6 g" v-if="checkPer('admins-edit')">
              <button
                class="btn btn-secondary btn-block"
                @click="admin.bus = false"
              >
                <i class="fa fa-globe"></i>
                مشرف للموقع
              </button>
            </div>
          </div>
          <div v-if="admin.bus != 'nothing'">
            <div class="col-12">
              <div class="card card-body border">
                <div class="form-check">
                  <h5 class="form-check-label">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="admin.all_rules"
                      :value="true"
                    />
                    مسموح له بكامل الصلاحيات
                  </h5>
                </div>
                <div v-if="!admin.all_rules">
                  <div v-if="admin.bus == true">
                    <hr />
                    <div class="form-check">
                      <h5 class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="admin.rules['alerts']"
                          :value="true"
                        />
                        ارسال تبيهات/اشعارات لأولياء الامور اثناء الرحلة (مثل:
                        سيتأخر الباص، الخ..)
                      </h5>
                    </div>
                  </div>
                  <div v-if="admin.bus == false">
                    <hr />
                    <div v-for="rule in rulesArray" :key="rule.title">
                      <template v-if="rule.title">
                        <div class="g border" style="margin-bottom: 5px">
                          <div class="form-check">
                            <h5 class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                v-model="admin.rules[rule.code]"
                                :value="true"
                              />
                              {{ rule.title }}
                            </h5>
                          </div>
                          <div v-if="admin.rules[rule.code]">
                            <div class="row g">
                              <div class="col-12 col-lg-3">
                                <div class="form-check">
                                  <h5 class="form-check-label">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      v-model="admin.rules[rule.code + '-add']"
                                      :value="true"
                                    />
                                    إضافة
                                  </h5>
                                </div>
                              </div>
                              <div class="col-12 col-lg-3">
                                <div class="form-check">
                                  <h5 class="form-check-label">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      v-model="admin.rules[rule.code + '-view']"
                                      :value="true"
                                    />
                                    عرض
                                  </h5>
                                </div>
                              </div>
                              <div class="col-12 col-lg-3">
                                <div class="form-check">
                                  <h5 class="form-check-label">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      v-model="admin.rules[rule.code + '-edit']"
                                      :value="true"
                                    />
                                    تعديل
                                  </h5>
                                </div>
                              </div>
                              <div class="col-12 col-lg-3">
                                <div class="form-check">
                                  <h5 class="form-check-label">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      v-model="
                                        admin.rules[rule.code + '-delete']
                                      "
                                      :value="true"
                                    />
                                    حذف
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <hr />
                  <div
                    class="col-12 g"
                    v-if="admin.rules['busses'] || admin.bus == true"
                  >
                    <br />
                    <strong class="g">الباصات المسموحة:</strong>
                    <br />
                    <br />
                    <ul>
                      <li v-for="bus in busses" :key="bus._id">
                        <div class="form-check">
                          <h5 class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name=""
                              id=""
                              v-model="admin.rules['bus-' + bus._id]"
                              :value="true"
                            />
                            {{ bus.title }}
                          </h5>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="col-12 g"
                    v-if="admin.rules['groups'] || admin.bus == true"
                  >
                    <br />
                    <strong class="g">خطوط السير المسموحة:</strong>
                    <br />
                    <br />
                    <ul>
                      <li v-for="group in groups" :key="group._id">
                        <div class="form-check">
                          <h5 class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name=""
                              id=""
                              v-model="admin.rules['group-' + group._id]"
                              :value="true"
                            />
                            {{ group.title }}
                          </h5>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="admin.bus != 'nothing' && checkPer('admins')">
            <div class="col-12 text-center">
              <button class="btn btn-primary" @click="done()">
                <span v-if="!edit">
                  اضافة المشرف <i class="fa fa-arrow-left"></i> </span
                ><span v-if="edit">
                  تعديل المشرف <i class="fa fa-arrow-left"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BFormCheckbox, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      admin: {
        bus: "nothing",
        all_rules: false,
        rules: {},
        section_id: null,
        password: 123456,
      },
      edit: false,
      groups: [],
      busses: [],
      sections: [],
      rulesArray: {
        admins: {
          code: "admins",
          title: "التحكم في مشرفين الموقع",
        },
        admins_bus: {
          code: "admins_bus",
          title: "التحكم في مشرفين الباصات",
        },
        students: {
          code: "students",
          title: "التحكم في الطلاب",
        },
        parents: {
          code: "parents",
          title: "التحكم في أولياء الأمور",
        },
        groups: {
          code: "groups",
          title: "التحكم في خطوط السير",
        },
        busses: {
          code: "busses",
          title: "التحكم في الباصات",
        },
        archive: {
          code: "archive",
          title: "التحكم في سجل الرحلات",
        },
        alerts: {
          code: "alerts",
          title: "استعراض تنبيهات مشرفين الباصات",
        },
        notifications: {
          code: "notifications",
          title: "استعراض الاشعارات السابقة",
        },
        settings: {
          code: "settings",
          title: "التحكم في اعدادات النظام",
        },
      },
    };
  },
  created() {
    var g = this;
    if (!checkPer("admins") && !checkPer("admins_bus")) {
      this.$router.push("/per");
      return false;
    }
    $.post(api + "/user/groups/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
        $.post(api + "/user/sections/list", {
          jwt: g.user.jwt,
        })
          .then(function (r) {
            g.sections = r.response;
            if (!g.admin.section_id && !window.location.hash) {
              g.admin.section_id = r.response[0]?._id;
            }
            $.post(api + "/user/busses/list", {
              jwt: g.user.jwt,
            })
              .then(function (r) {
                g.busses = r.response;
                g.loading = false;
              })
              .catch(function (e) {
                console.log(e);
                alert("حدث خطأ..");
              });
          })
          .catch(function (e) {
            console.log(e);
            alert("حدث خطأ..");
          });
      })
      .catch(function (e) {
        console.log(e);
        alert("حدث خطأ..");
      });
    if (window.location.hash) {
      g.edit = "true";
      g.loading = true;
      $.post(api + "/user/admins/admin", {
        jwt: g.user.jwt,
        id: window.location.hash.split("#")[1],
      })
        .then(function (r) {
          g.loading = false;
          g.admin = r.response;
          if (!g.admin.rules) {
            g.admin.rules = {};
          }
          for (const property in g.admin.rules) {
            if (g.admin.rules[property] != "true") {
              delete g.admin.rules[property];
            }
          }
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
          g.loading = false;
        });
    }
  },
  methods: {
    done() {
      var g = this;
      g.loading = true;
      for (const property in g.admin.rules) {
        if (g.admin.rules[property] != "true" && !g.admin.rules[property]) {
          delete g.admin.rules[property];
        }
      }
      $.post(api + "/user/admins/add-or-edit", {
        jwt: g.user.jwt,
        admin: g.admin,
        edit: g.edit,
      })
        .then(function (r) {
          g.loading = false;
          if (r.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: r.response,
                icon: "XIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
          g.loading = false;
        });
    },
    checkPer(e) {
      return checkPer(e);
    },
  },
};
</script>

<style>
</style>